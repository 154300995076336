@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.action-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;

  .v-input {
    max-width: 400px;
  }
}
::v-deep .col-ds {
  width: 25%;
}

::v-deep .col-role {
  width: 10%;
}

::v-deep .col-measure-type {
  width: 15%;
}

::v-deep .col-unit {
  width: 15%;
}

::v-deep .col-last-reception-time {
  width: 20%;
}

::v-deep .col-last-timestamp {
  width: 25%;
}

::v-deep .col-measure-value {
  width: 20%;
}
